export default {
    "electricVehicles": "Electric Vehicles",
    "usedElectricVehicles" : "Used Electric Vehicles",
    "compareVehicles": "Compare Vehicles",
    "incentives" : "Incentives",
    "chargingStations" : "Charging Stations",
    "homeChargers" : "Home Chargers",
    "dealers" : "Dealers",
    "newVehicles" : "New Vehicles",

    "electricity" : "Electricity",
    "gasoline" : "Gasoline",
    "total" : "Total",
    "description" : "Description",
    "detailedCalculations" : "DETAILED CALCULATIONS",
    "charging" : "Charging",
    "emissions" : "Emissions",
    "source" : "Source",
    "vehicles" : "Vehicles",
    "make" : "Make",
    "model" : "Model",
    "all" : "All",
    "age" : "Age",
    "zipcode" : "Zipcode",

    "personalizeIncentives" : "PERSONALIZE INCENTIVES",

    "vehicle.miles" : "miles",
    "vehicle.trees" : "trees",
    "vehicle.view" : "View",
    "vehicle.more" : "more",
    "vehicle.fewer" : "fewer",
    "vehicle.greater" : "greater",

    "vehicle.plugInHybrid": "Plug in Hybrid",
    "vehicle.allElectric": "All Electric",
    "vehicle.gas": "Gasoline",
    "vehicle.afterIncentives" : "AFTER INCENTIVES",
    "vehicle.msrp" : "MSRP",
    "vehicle.estimatedIncentives" : "ESTIMATED INCENTIVES",
    "vehicle.type" : "TYPE",
    "vehicle.fuelType" : "FUEL TYPE",
    "vehicle.batterySize" : "BATTERY SIZE",
    "vehicle.electricRange" : "ELECTRIC RANGE",
    "vehicle.timeToChargeLvlTwo" : "TIME TO CHARGE - LEVEL 2",
    "vehicle.milesPerThirtyFastCharge" : "MILES PER 30 MIN OF FAST CHARGING",
    "vehicle.coTwoEmissions" : "CO2 EMISSIONS REDUCTION",
    "vehicle.phev" : "Electricity and Gasoline",
    "vehicle.bev" : "Electricity",
    "vehicle.age.one" : "0-1 years old",
    "vehicle.age.two" : "2-3 years old",
    "vehicle.age.four" : "4+ years old",

    "vehicle.type.sedan" : "Sedan",
    "vehicle.type.hatchback" : "Hatchback",
    "vehicle.type.coupe" : "Coupe",
    "vehicle.type.crossover" : "Crossover",
    "vehicle.type.minivan" : "Minivan",
    "vehicle.type.suv" : "SUV",
    "vehicle.type.wagon" : "Wagon",
    "vehicle.type.truck" : "Truck",

    "header.electricVehicles": "Electric Vehicles",
    "header.compareVehicles": "Compare Vehicles",
    "header.incentives" : "Incentives",
    "header.chargingStations" : "Charging Stations",
    "header.homeChargers" : "Home Chargers",
    
    "footer.electricVehicles" : "{electricVehicles}",
    "footer.compareVehicles": "{compareVehicles}",
    "footer.incentives" : "{incentives}",
    "footer.chargingStations" : "{chargingStations}",
    "footer.homeChargers" : "{homeChargers}",
    "footer.allRights" : "All Rights Reserved.",
    "footer.poweredBy" : "Powered by Zappyride",

    "homepage.welcome": "Colton Recharged",
    "homepage.welcomeSub" : "Your electric car guide, brought to you by Colton Electric Utility. Estimate and compare costs, savings, EV Incentives, and more.",

    "homepage.linkCard.browseElectricVehicles" : "BROWSE ELECTRIC {lineBreak} VEHICLES",
    "homepage.linkCard.discoverIncentives" : "DISCOVER {lineBreak} INCENTIVES",
    "homepage.linkCard.locateChargingStations" : "LOCATE CHARGING {lineBreak} STATIONS",
    "homepage.linkCard.homeChargers" : "HOME {lineBreak} CHARGERS",

    "homepage.vehicleCarousel.title" : "There are {vehicleCount} electric vehicles available. Discover yours.",
    "homepage.vehicleCarousel.subTitle" : "Choose an EV and compare the cost to a similar gas vehicle. EVs can help you save money because {lineBreak} they're often cheaper to own and maintain than a 100% gasoline-powered competitor.",
    "homepage.vehicleCarousel.findEVButton" : "FIND YOUR ELECTRIC VEHICLE",

    "graph.assumptions" : "Assumptions",
    "graph.yourEV" : "Your EV Selection",
    "graph.similarGasVehicle" : "Similar Gas Vehicle",
    "graph.graphValues": "GRAPH VALUES",
    "graph.show" : "SHOW ",
    "graph.hide" : "HIDE ",
    "graph.moreExpensive" : "more expensive",
    "graph.cheaper" :"cheaper",
    "graph.toOwnOver" : "to own over ",
    "graph.years" : " Years",
    "graph.year" : " Year",

    "graph.costOfOwnership.title" : "Cost of Ownership",
    "graph.costOfOwnership.subTitle" : "The {carName} is {style} {costDeltaText} {costDeltaTextEnding}",
    "graph.costOfOwnership.subTitleThreeCars" : "The {carName} is the cheapest to own for {yearsOfOwnership} years",
    "graph.costOfOwnership.descriptionRow.vehicle" : "Vehicle",
    "graph.costOfOwnership.descriptionRow.maintenance" : "Maintenance",
    "graph.costOfOwnership.descriptionRow.insurance" : "Insurance",
    "graph.costOfOwnership.descriptionRow.electricity" : "Electricity",
    "graph.costOfOwnership.descriptionRow.gasoline" : "Gasoline",
    "graph.costOfOwnership.totalRow" : "Total",
    "graph.costOfOwnership.description" : "Description",

    "graph.costOfOwnership.chart.vehicle" : "Vehicle net Incentives, Resale",
    "graph.costOfOwnership.chart.electricity" : "Electricity",
    "graph.costOfOwnership.chart.gasoline" : "Gasoline",
    "graph.costOfOwnership.chart.maintenance" : "Maintenance",
    "graph.costOfOwnership.chart.insurance" : "Insurance",

    "graph.title.costAnalysis" : "Cost Analysis",
    "graph.title.speedOfCharge" : "Speed of Charge",
    "graph.title.electricRange" : "Electric Range",
    "graph.monthlyCostToFill.title" : "Cost to Fill Up Monthly",

    
    "graph.speedOfCharge.levelTwoChargingSpeed" : "Level 2 Charger Speed",
    "graph.speedOfCharge.fastChargingSpeed" : "Fast Charging Speed",
    "graph.speedOfCharge.mphLevelTwoCharger" : "miles per hour with a level 2 charger",
    "graph.speedOfCharge.thirtyFastCharge" : "miles per 30 minutes of fast charging",
    "graph.speedOfCharge.subTitle" : "The {car} charges {number} {costDeltaText} {subTitleText}",
    "graph.speedOfCharge.mphLevelTwoChargerThreeCars" : "charges the most miles per hour with a level 2 charger",
    "graph.speedOfCharge.thirtyFastChargeThreeCars" : "charges the most miles per 30 minutes of fast charging",
    "graph.speedOfCharge.subTitleThreeCars" : "The {car} {subTitleText}",
    "graph.speedOfCharge.mphLevelTwoChargerTitle" : "Amount of Charge Per Hour of Level 2 Charging",
    "graph.speedOfCharge.fastChargerTitle" : "Amount of Charge Per 30 Minutes of Fast Charging",
    "graph.electricRange.subTitleThreeCars" : "The {car} has the longest Electric Range",
    "graph.electricRange.subTitle" : "The {car}'s electric range is {costDeltaText} by {number} miles",
    "graph.electricRange.gasolineRange" : "Gasoline Range",
    "graph.electricRange.assumption" : "Range based on EPA Estimates",
    "graph.monthlyCostToFill.subTitle" : "The {carName} is {style} {costDeltaText} to fill up monthly",
    "graph.monthlyCostToFill.subTitleThreeCars" : "The {carName} is cheapest to fill up monthly",

    "homepage.incentives.title" : "Explore potential EV incentives and tax credits",
    "homepage.incentives.subTitle" : "See how much you could save getting behind the wheel of an EV, whether you are buying or leasing. {lineBreak} Incentives are personalized for where you live.",
    "homepage.incentives.exporeIncentives" : "EXPLORE INCENTIVES",


    "compareVehicles.subTitle" : "See All Vehicles >",
    "compareVehicles.selectFuel" : "Select a Fuel Type",
    "compareVehicles.selectMake" : "Select a Make",
    "compareVehicles.selectModel" : "Select a Model",
    "compareVehicles.pickTwo" : "Pick at least 2 vehicles to start the comparison",
    "compareVehicles.disclaimer" : "Not all make / models are available.",

    "evs.welcomeSub" : "Compare electric vehicles by range, price, or your personalized Match Score. {lineBreak} Click on the EV for more details, including total cost compared to a similar gas vehicle.",
    "evs.buttonMatchScoreAndFilters" : "Match Score and Filters",
    "evs.matchScoreAndFilters" : "Refine Match Score and Filters",
    "evs.matchScoreOptions" : "Refine Match Score",
    "evs.roundTripCommute" : "Roundtrip Commute",
    "evs.budgetAfterIncentives" : "Budget after Incentives",
    "evs.minSeats" : "Minimum Seats",
    "evs.seats" : "seats",
    "evs.homeChargingAvailability" : "Home Charging Availability",
    "evs.homeChargingAvailabilityTooltip" : "More electric vehicles will be convenient for you if you can charge quickly at home.",
    "evs.noCharging" : "No Charging",
    "evs.levelOne" : "Level 1",
    "evs.levelTwo" : "Level 2",
    "evs.helpMeChoose" : "HELP ME CHOOSE",
    "evs.chargingAvailability" : "Charging Availability",
    "evs.disclaimer" : "Vehicles displayed may not reflect actual availability. {company} does not endorse or recommend any specific vehicle or car manufacturer.",
    "evs.chargercard.chargingtime.na": "NA",

    "chargingWizard.carOvernight" : "Where will you park your car overnight?",
    "chargingWizard.parkingGarage" : "Private garage or parking spot",
    "chargingWizard.onTheStreet" : "On the street",
    "chargingWizard.publicParking" : "Public parking lot",
    "chargingWizard.electricianInstall" : "Would an electrician be allowed to install a charging station?",
    "chargingWizard.overnightParking" : "Could you park the car overnight in a spot equipped with a charging station?",
    "chargingWizard.powerOutlet" : "Is there a regular power outlet close to where you park your car?",
    "chargingWizard.chargingAvailability" : " charging availability",
    "chargingWizard.noChargingAvailability" : "no charging availability",
    "chargingWizard.youWouldHave" : "you would have {resultText} ",
    "chargingWizard.useThisValue" : "Use this value",

    "evfilter" : "Filter",
    "evfilter.fuel" : "Fuel",
    "evfilter.fuelTooltip" : "All-electric vehicles use electricity only. Plug-in hybrids can use electricity and gasoline.",
    "evfilter.type" : "Type",

    "yes" : "Yes",
    "no" : "No",

    "evCard.electricRange" : "Electric Range",
    "evCard.totalRange" : "Total Range",
    "evCard.matchScore" : "MATCH SCORE",
    "evCard.seeDetails" : "See Details",
    "evCard.seeElectricVehicle" : "SEE ELECTRIC VEHICLE",

    "evSort.title" : "Sort By",
    "evSort.matchScore" : "Match Score",
    "evSort.electricRange" : "Electric Range",
    "evSort.priceLtH" : "Price: Low to High",
    "evSort.priceHtL" : "Price: High to Low",
    "evSort.alphabetical" : "Alphabetical",

    "pricePanels.cash" : "Cash",
    "pricePanels.loan" : "Loan",
    "pricePanels.lease" : "Lease",
    "pricePanels.loanPayment" : "Loan Payment",
    "pricePanels.downPayment" : "Down Payment",
    "pricePanels.leasePayment" : "Lease Payment",
    "pricePanels.perMonth" : " / month",
    "pricePanels.downPaymentDescription" : "10% of MSRP plus tax",
    "pricePanels.firstLeasePayment" : "FIRST LEASE PAYMENT",
    "pricePanels.incentivesForLease" : "Incentives For Lease",
    "pricePanels.seeAllIncentives" : "See All Incentives",
    "pricePanels.seeAllCosts" : "See All Costs",

    "ev.jumbotron.viewAllCars" : "VIEW ALL CARS",
    "ev.carDetails.fastChargingTooltip" : "DC Fast Charger Speed:",
    "ev.carDetails.levelTwoChargerSpeedTooltip" : "Level 2 Charger Speed:",
    "ev.carDetails.batterySizeTooltip" : "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. A typical laptop battery can hold less than 0.1 kWh.",
    "ev.carDetails.treesPlanted" : "Trees Planted",
    "ev.carDetails.gasolineSaved" : "Gasoline Saved",
    "ev.carDetails.compareText" : "We selected the {gasVehicle} based on its proximity to the {vehicle} based on brand, size, and price.",
    "ev.cardetails.calc.dcRate" : "DC Fast Charging (DCFC) Rate",
    "ev.cardetails.calc.EPA" : "EPA Efficiency Rating",
    "ev.cardetails.calc.chargingRate" : "Charging Rate",
    "ev.cardetails.calc.vehicleBatteryCapacity" : "Vehicle Battery Capacity",
    "ev.cardetails.calc.maxACIntake" : "Vehicle Max AC Intake",
    "ev.cardetails.calc.lvlTwoChargingRate" : "Level 2 Charging Rate",
    "ev.cardetails.calc.minOflvl2AC" : "Minimum of Level 2 Charging Rate and Vehicle Max AC Intake",
    "ev.cardetails.calc.lvl2FullCharge" : "Time to Full Charge for Level 2",
    "ev.cardetails.calc.milesDrivenPerYr" : "Miles driven per year",
    "ev.cardetails.calc.userInput" : "User Input",
    "ev.cardetails.calc.milesPerGallon" : "Miles Per Gallon (MPG)",
    "ev.cardetails.calc.mpg" : " miles/gal",
    "ev.cardetails.calc.portionElectric" : "Portion of Electric Driving",
    "ev.cardetails.calc.portionElectricSource" : "User input. For a hybrid vehicle, estimates the portion of miles driven using the electric battery instead of gasoline.",
    "ev.cardetails.calc.gallonsUsed" : "Gallons Used",
    "ev.cardetails.calc.gallonsUsedFormula" : "Miles driven * (1 - Portion of Electric Driving) / MPG",
    "ev.cardetails.calc.gallonsSaved" : "Gallons Saved",
    "ev.cardetails.calc.gallonsSavedFormula" : "Gallons used by Equivalent Gas Vehicle - Gallons used by EV",
    "ev.cardetails.calc.electricEmissions" : "Electric Emissions in Lbs",
    "ev.cardetails.calc.electricEmissionsLbsMwh" : "Electric Emissions in Lbs/MWh",
    "ev.cardetails.calc.EIA" : "U.S. Energy Information Administration",
    "ev.cardetails.calc.gasolineEmissionsYr" : "Gasoline Emissions in lbs of CO2/yr",
    "ev.cardetails.calc.gasolineEmissions" : "Gasoline Emissions in Lbs per Gallon",
    "ev.cardetails.calc.emissionsReudctions" : "Emissions Reductions",
    "ev.cardetails.calc.lbsCo2PerYr" : " lbs of CO2 per year",
    "ev.cardetails.calc.gasolineEmissionsLbs" : "Gasoline Emissions in Lbs",
    "ev.cardetails.calc.arborDayFoundation" : "Arbor Day Founadtion",
    "ev.cardetails.calc.emissionsPerTree" : "Emissions Saved per Tree",
    "ev.cardetails.calc.electricityEmissions" : "Electricity Emissions in CO2 lbs/MWh",
    "ev.usedCarTitle" : "Used {car}",
    "ev.cardetails.electricRange" : "To account for battery degradation, the range quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.",
    "ev.carDetails.usedBatterySizeTooltip" : "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. To account for battery degradation for a used EV, the battery size quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.",

    "ev.purchaseMethod" : "Purchase Method",
    "ev.purchaseMethodToolTip" : "In a lease, you have to return the car after the lease period. In the case of loan financing, you keep the car after the loan is paid off.",
    "ev.cash" : "Cash",
    "ev.loan" : "Loan",
    "ev.lease" : "Lease",
    "ev.milesDrivenAnnually" : "Miles Driven Per Year",
    "ev.electricPortion" : "Portion Electric for PHEV",
    "ev.electricPortionTooltip" : "A Plug-In Hybrid Electric Vehicle (PHEV) can use both electricity and gasoline. It is usually more beneficial to use electricity.",
    "ev.yearsOwnership" : "Years of Ownership/Lease",
    "ev.interestRate" : "Interest Rate",
    "ev.electricityRateCalc" : "Electricity rate is calculated as a blended rate based off an average household consumption of 700 kWh. To learn more, please click {website}",

    "assumption.salesTax" : "Sales Tax",
    "assumption.gasolinePrice" : "Price of Gasoline",
    "assumption.downPayment" : "Down Payment",
    "assumption.electricityRate" : "Electricity Rate",
    "assumption.downPaymentValue" : "10% of MSRP + Tax",

    "ev.compareGasVehicle" : "Compare the {car} to a similar gas vehicle, the {gasCar}",
    "ev.compareOtherVehicles" : "COMPARE OTHER VEHICLES",
    "ev.incentives.title" : "Up to {savings} in tax credits and rebates are potentially available {lineBreak} for the {car}",
    "ev.reviews" : "Reviews from around the web",

    "chargingMap.updateZipcode" : "UPDATE ZIP CODE",
    "chargingMap.errorZipcode" : "Error: {workingZipcode} is not a valid zipcode",
    "chargingMap.showFullListOfStations" : "FULL LIST OF STATIONS",
    "chargingMap.public" : "Public stations",
    "chargingMap.businessGovernment" : "Installed by business or government",
    "chargingMap.highPowerStations" : "High Power Stations",
    "chargingMap.DCChargers" : "DC fast charge or superchargers",
    "chargingMap.otherStations" : "Other Types of Stations",
    "chargingMap.privateStations" : "Private stations",
    "chargingMap.searchDealers" : "Search Qualified Dealers",

    "dealers.brands" : "Brands",
    "dealers.allBrands" : "All Brands",
    "dealers.allDealers" : "All Dealers",
    "dealers.contact" : "CONTACT",
    "dealers.website" : "WEBSITE",
    "dealers.findDealers" : "Find Dealers",

    "incentives.title" : "Electric Vehicle Incentives",
    "incentives.subTitle" : "You may be eligible for a range of incentives, including rebates, tax credits, and various other benefits. Incentives are personalized for where you live.",
    "incentives.single" : "Single",
    "incentives.married" : "Married",
    "incentives.headOfHousehold" : "Head of Household",
    "incentives.vehicleType" : "Vehicle Type",
    "incentives.vehicleTypeTooltip" : "Certain incentives depend on which car you purchase.",
    "incentives.tradeIn" : "Trade-In",
    "incentives.income" : "Income and tax status",
    "incentives.incomeTooltip" : "Incentive eligibility and the amount you can receive often depends on your income. Lower  income customers are typically eligible for higher rebates.",
    "incentives.clunkerNone" : "Certain incentives compensate you for retiring an existing vehicle, also known as a clunker.",
    "incentives.clunkertoolTip" : "Certain incentives compensate you for retiring an existing vehicle, also known as a clunker. Specifically, the ",
    "incentives.clunkertoolTipContd" : " are available in your region. Eligibility requirements vary by incentive; please check program details.",
    "incentives.householdSize" : "Household Size",
    "incentives.householdIncome" : "Household Income",
    "incentives.planClunker" : "Are you planning to turn in a clunker?",
    "incentives.location" : "Location",
    "incentives.locationTooltip" : "Many incentives depend on where you live. Enter your zipcode to help determine what incentives are available where you live.",
    "incentives.taxFilingStatus" : "Tax Filing Status",
    "incentives.whichVehicle" : "Which vehicle do you plan to purchase?",
    "incentives.incentiveEligibility" : "Update Incentive Eligibility",

    "homeChargers.subText" : "Select the vehicle you are interested in to see the specific charging time to a full battery.",
    "homeChargers.wallMounted" : "Wall Mounted",
    "homeChargers.portable" : "Portable",
    "homeChargers.price" : "Price",
    "homeChargers.cordLength" : "Cord Length",
    "homeChargers.wifi" : "WiFi Connectivity",
    "homeChargers.socket" : "Socket",
    "homeChargers.socketTooltip" : "Different model chargers have different plug patterns. These plug patterns correspond to the way prongs fit into your wall outlet. If you're note sure which is best for your home/garage, check with a qualified electrician.",
    "homeChargers.cordLengthLtH" : "Cord Length: Low to High",
    "homeChargers.cordLengthHtL" : "Cord Length: High to Low",
    "homeChargersCard.buy" : "Details & Buy",
    "homeChargersCard.beforeIncentives" : "Before Incentives",
    "homeChargersCard.socket" : "{socket} Socket",
    "homeChargers.cable" : "{length} ft. Cable",
    "homeChargers.hardwired" : "Hardwired",
    "homeChargers.toFullCharge" : "to full charge", 
    "homeChargersCard.teslaWarning" : "This charger only works with Teslas",
    "homeChargers.incentives" : "Apply for Incentives",
    
    "usedEVCard.yearRange" : "Year Range",
    "usedEVCard.electricRangeAverage" : "Electric Range Average",
}

