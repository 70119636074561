import React from "react";
import PropTypes from "prop-types";
import LinkCard from "../../LinkCard/LinkCard"
import backgroundImg from "../../../assets/images/banner.svg";
import {FormattedMessage} from 'react-intl';

const HomepageBigPromise = ({
  homePageBannerImage
}) => {
  return (
    <>
      <section
        className="container-fluid homepage-jumbotron"
        style={{
          backgroundImage: "url(" + backgroundImg + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          WebkitBackgroundSize: "cover",
          MozBackgroundSize: "cover",
          OBackgroundSize: "cover",
          backgroundSize: "cover",
          padding: 0,
          textAlign: "center"
        }}
      >
        <div className="big-promise">
          <div className="hp-header-holder text-center">
            <h1 className="hp-welcome text-center">
              <FormattedMessage
                id="homepage.welcome"
                defaultMessage="Colton Recharged"
                description="Homepage Title"
              />
            </h1>
            <div className="line"></div>
            <p className="hp-welcome-sub">
              <FormattedMessage 
                id="homepage.welcomeSub"
                defaultMessage="Your electric car guide, brought to you by Colton Electric Utility. Estimate and compare costs, savings, EV Incentives, and more. "
                description="Description of Colton Recharged"
                // values= {{
                //   lineBreak: <br />
                // }}
              />
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid" id="homepage-cards-container">
        <div className="homepage-cards-container">
          <div className="link-card-container">
            <LinkCard type="BROWSE_VEHICLES" />
            <LinkCard type="DISCOVER_INCENTIVES" />
            <LinkCard type="LOCATE_CHARGING_STATIONS" />
            <LinkCard type="HOME_CHARGERS" />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string
}