import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { FormatAsCents } from "../../../utils/Helpers/Format";

import Range from "../../shared/InputElements/Range";

const descriptionFn = val => FormatAsCents(val / 100);

const SlideGasolinePrice = ({
  id = "gasoline-price-range",
  label = "Gasoline Price ($/gal)",
  description = descriptionFn,
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);

  return (
    <Range
      id={id}
      value={userPrefs.get("gasolinePriceInCentsPerGal")}
      label={label}
      rangeMin={100}
      rangeMax={1000}
      rangeStep={10}
      description={description}
      handler={e =>
        userPrefs.set({ gasolinePriceInCentsPerGal: e.target.value })
      }
      {...rest}
    />
  );
};

export default SlideGasolinePrice;

SlideGasolinePrice.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func,
  tooltip: PropTypes.string
};
