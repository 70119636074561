import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import getFilteredData from "../../../../utils/Helpers/getFilteredData"
import IncentiveCatalog from "../../../../components/IncentiveCatalog/IncentiveCatalog";
import homepageIncentiveTop from "../../../assets/images/homepage-incentive-top.svg"
import homepageIncentiveBtm from "../../../assets/images/homepage-incentive-btm.svg"

import {FormattedMessage} from 'react-intl';

const HomepageIncentives = ({ incentives }) => {

  incentives = getFilteredData([
    { field: "type", value: "Discounted Electric Rate", count: 1 },
    { field: "grantor_type", value: "State", count: 2 },
    { field: "grantor_type", value: "Power Supplier", count: 1 }
  ], incentives)

  return (
    <>
      <section 
        className="container-fluid pb-0" 
        style={{
          backgroundImage: "url(" + homepageIncentiveTop + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          WebkitBackgroundSize: "cover",
          MozBackgroundSize: "cover",
          OBackgroundSize: "cover",
          backgroundSize: "cover",
          paddingTop: "18rem"}}
        >
      </section>
      <section
        className="container-fluid pb-0" 
        id="HomepageIncentives"
      >
        <div className="row">
          <div className="col-sm-12 text-center">
            <h3>
              <FormattedMessage 
                  id="homepage.incentives.title"
                  defaultMessage="Explore potential EV incentives and tax credits"
                  description="Homepage Incentives Title"
              />
            </h3>
            <p className="lead">
            <FormattedMessage 
                id="homepage.incentives.subTitle"
                defaultMessage="See how much you could save getting behind the wheel of an EV,
                whether you are buying or leasing. {lineBreak} Incentives are personalized for
                where you live."
                description="Homepage Incentives Sub Title"
                values= {{
                  lineBreak: <br />
                }}
              />
            </p>
            <Link to="/incentives" style={{margin:"12px"}} className="btn btn-ae" role="button">
              <FormattedMessage 
                  id="homepage.incentives.exporeIncentives"
                  defaultMessage="EXPLORE INCENTIVES"
                  description="Explore Incentives Button"
                />
            </Link>
          </div>
        </div>
        <IncentiveCatalog incentives={incentives} category="all" />
      </section>
      <section 
        className="container-fluid pb-0" 
        style={{
          backgroundImage: "url(" + homepageIncentiveBtm + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          WebkitBackgroundSize: "cover",
          MozBackgroundSize: "cover",
          OBackgroundSize: "cover",
          backgroundSize: "cover",
          paddingTop: "9rem"}}
        >
      </section>
    </>
  );
};

export default HomepageIncentives;

HomepageIncentives.propTypes = {
  incentives: PropTypes.array
};
