import React from "react";
import PropTypes from "prop-types";
import { FormatCarName, FormatAsInt } from "../../utils/Helpers/Format"
import "chartjs-plugin-datalabels";
import { HorizontalBar } from "react-chartjs-2";
import calcCharging from "../../functions/vehicle/Charging/calcCharging"

import { FormattedMessage, useIntl } from 'react-intl';

const ChargingRangeGraph = ({
  cars,
  hasExtraPadding,
  chargerLevel
}) => {

  const intl = useIntl()

  if (!cars || cars.length === 0) return null;

  cars = cars.filter( car => {
    return car !== null
  })

  const carsChargingSpeeds = cars.map(car => {

    if (car.electric_efficiency === "N/A") {
        return {car: car, chargingSpeed: 0}
    }
    let chargingSpeed = FormatAsInt(
        calcCharging.milesPerHalfHourOfCharge(
            car.electric_efficiency, chargerLevel
    ))
    if (chargerLevel === "level_2") {
        chargingSpeed *= 2
    }
    return {car: car, chargingSpeed: chargingSpeed}
  })

  const sortedCarSpeeds = [...carsChargingSpeeds].sort((carSpeed1, carSpeed2) => {
    return carSpeed2.chargingSpeed - carSpeed1.chargingSpeed
  })

  const subTitle = cars => {
    if (cars.length === 2) {
      const costDelta = carsChargingSpeeds[1].chargingSpeed - carsChargingSpeeds[0].chargingSpeed;
      const costDeltaText = costDelta < 0 ? intl.formatMessage({ id: "vehicle.more", defaultMessage: "more"}) : intl.formatMessage({ id: "vehicle.fewer", defaultMessage: "fewer"});
      const subTitleText = chargerLevel === "level_2" ? intl.formatMessage({ id: "graph.speedOfCharge.mphLevelTwoCharger", defaultMessage: "miles per hour with a level 2 charger"}) : intl.formatMessage({ id: "graph.speedOfCharge.thirtyFastCharge", defaultMessage: "miles per 30 minutes of fast charging"})
      return(
        <p className="h3 my-3 graph-sub-title">
          <FormattedMessage 
                id="graph.speedOfCharge.subTitle"
                defaultMessage="The {car} charges {number} {costDeltaText} {subTitleText}"
                description="Speed of Charge Sub Title Two Cars"
                values= {{
                  car: FormatCarName(cars[0]),
                  number: <strong style={{ fontWeight: 800 }}>
                            {(Math.abs(costDelta))}
                          </strong>,
                  costDeltaText: costDeltaText,
                  subTitleText: subTitleText
                }}
              />
        </p>
      );
    } else {
      const subTitleText = chargerLevel === "level_2" ? intl.formatMessage({ id: "graph.speedOfCharge.mphLevelTwoChargerThreeCars", defaultMessage: "charges the most miles per hour with a level 2 charger"}) : intl.formatMessage({ id: "graph.speedOfCharge.thirtyFastChargeThreeCars", defaultMessage: "charges the most miles per 30 minutes of fast charging"})
      return (
        <p className="h3 my-3 graph-sub-title">
          <FormattedMessage 
                id="graph.speedOfCharge.subTitleThreeCars"
                defaultMessage="The {car} {subTitleText}"
                description="Speed of Charge Sub Title Three Cars"
                values= {{
                  car: FormatCarName(sortedCarSpeeds[0].car),
                  subTitleText: subTitleText
                }}
            />
        </p>
      )
    }
  }

  const titleText = chargerLevel === "level_2" ? intl.formatMessage({ id: "graph.speedOfCharge.mphLevelTwoChargerTitle", defaultMessage: "Amount of Charge Per Hour of Level 2 Charging"}) : intl.formatMessage({ id: "graph.speedOfCharge.fastChargerTitle", defaultMessage: "Amount of Charge Per 30 Minutes of Fast Charging"})

  const title = (
    <>
      <p className="h3 my-3">{titleText}</p>
      {subTitle(cars)}
    </>
  )

  const carNames = cars.map(car => FormatCarName(car))

  const chartData = {
    labels: carNames,
    datasets: [
      {
        label: intl.formatMessage({ id: "vehicle.miles", defaultMessage: "Miles"}),
        backgroundColor: ["#59BEC9", "#007C91", "#006269"],
        data: carsChargingSpeeds.map(car => car.chargingSpeed)
      }
    ]
  };

  const CHART_JS_OPTIONS = {
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          var value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          var label = data.datasets[tooltipItem.datasetIndex].label || "";
          value = FormatAsInt(value);
          return " " + label + ": " + value;
        }
      }
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            callback: function(value) {
              return value;
            },
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: intl.formatMessage({ id: "vehicle.miles", defaultMessage: "Miles"}),
            fontSize: 10,
            fontColor: "#2b2b2b"
        }
        }
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            font: {
              weight: 700
            }
          }
        }
      ]
    },
    legend: {
      display: false
    },
    layout: {
      padding: {
        right: 75
      }
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let datasets = ctx.chart.data.datasets;
          if (ctx.datasetIndex === datasets.length - 1) {
            let sum = datasets[0].data[ctx.dataIndex];
            return sum === 0 ? `N/A` : `${FormatAsInt(sum)} ${intl.formatMessage({ id: "vehicle.miles", defaultMessage: "Miles"})}`;
          } else {
            return "";
          }
        },
        align: "end",
        anchor: "end",
        color: "#333333",
        font: {
          weight: 700
        }
      }
    }
  };

  return (
    <div
      className="input-well text-center"
      style={hasExtraPadding ? { padding: "2.5rem" } : { paddfing: "1rem" }}
    >
      {title}
      <div className="d-none d-lg-block d-xl-block">
        <div>
          <HorizontalBar
            data={chartData}
            height={212}
            type="horizontalBar"
            options={CHART_JS_OPTIONS}
          />
        </div>
      </div>
      {hasExtraPadding && <br />}
    </div>
  );
};

export default ChargingRangeGraph;

ChargingRangeGraph.propTypes = {
  cars: PropTypes.array,
  forceUserPrefsPresets: PropTypes.bool,
  hasExtraPadding: PropTypes.bool
};
