import React from "react";
import PropTypes from "prop-types";

import "./LinkCard.scss";

import BROWSE_VEHICLES from "../../assets/images/icons/browse-electric-vehicles.svg"
import DISCOVER_INCENTIVES from "../../assets/images/icons/discover-incentives.svg";
import LOCATE_CHARGING_STATIONS from "../../assets/images/icons/locate-charging-stations.svg";
import HOME_CHARGERS from "../../assets/images/icons/home-chargers.svg"
import { Link } from "react-router-dom";
import SmoothScroll from "../../../utils/Helpers/SmoothScroll"
import {FormattedMessage} from 'react-intl';

const LinkCard = ({ type }) => {
  let src, alt, link, scrollTo, title, target;

  switch (type) {
    case "BROWSE_VEHICLES":
      src = BROWSE_VEHICLES;
      alt = "Electric Vehicle"
      scrollTo = "HomepageVehiclesCarousel";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.browseElectricVehicles"
            defaultMessage="BROWSE ELECTRIC {lineBreak} VEHICLES"
            description="HomePage Linkcard Browse Electric Vehicles"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      break;
    case "DISCOVER_INCENTIVES":
      src = DISCOVER_INCENTIVES;
      alt = "Money Bag"
      scrollTo = "HomepageIncentives";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.discoverIncentives"
            defaultMessage="DISCOVER {lineBreak} INCENTIVES"
            description="HomePage Linkcard Discover Incentives"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      break;
    case "LOCATE_CHARGING_STATIONS":
      src = LOCATE_CHARGING_STATIONS;
      alt = "Charging Station"
      link = "/charging-stations";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.locateChargingStations"
            defaultMessage="LOCATE CHARGING {lineBreak} STATIONS"
            description="HomePage Linkcard Locate Charging Stations"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      break;
    case "HOME_CHARGERS":
      src = HOME_CHARGERS;
      alt = "Home Chargers"
      link = "/home-chargers";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.homeChargers"
            defaultMessage="HOME {lineBreak} CHARGERS"
            description="HomePage Linkcard Home Chargers"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      break;
    default:
      return null;
  }

  return scrollTo ? (
    <div className="link-card" onClick={e => SmoothScroll(scrollTo)}>
      <div className="inside-link-card" >
        <div>
          <img src={src} alt={alt} />
          <p className="h3">{title}</p>
        </div>
      </div>
    </div>
  ) : (
    <div className="link-card">
      <Link to={link} className="inside-link-card" target={target}>
          <img src={src} alt={alt} />
          <p className="h3">{title}</p>
      </Link>
    </div>
  );
};

export default LinkCard;

LinkCard.propTypes = {
  type: PropTypes.string
};
