import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {   Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle } from "reactstrap";
import {FormattedMessage} from 'react-intl';

import logo from "../../../assets/images/logo.png"
// import coltonIcon from "../../../assets/images/colton.png"

import "./Header.scss";

const Header = ({ page }) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <div className="Header">
      <div className="container" style={{ padding: 0 }}>
        <Navbar expand="lg">
          <div style={{ paddingTop: "0.75rem", paddingBottom: "0.75rem" }}>
            {/* <a
              href="/"
              rel="noopener noreferrer"
              className="navbar-brand"
            > */}
            <NavLink
                  title={page === "vehicles" ? "Active Page" : null}
                  to="/"
            >
              <img
                src={logo}
                alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                className="img-fluid"
                style={{ maxWidth: "200px" }}
              />
            {/* </a> */}
            </NavLink>
          </div>
          <NavbarToggler onClick={() => setCollapse(!collapse)} aria-label={collapse ? 'Close navigation' : 'Open navigation'} />
          <Collapse isOpen={collapse} navbar>
            <Nav className="ml-auto" navbar>
              {/* { process.env.REACT_APP_PAGES_VEHICLES_ENABLED  ? (
              <NavItem>
                <NavLink
                  title={page === "vehicles" ? "Active Page" : null}
                  to="/vehicles"
                >
                  <span>
                    <FormattedMessage 
                      id="electricVehicles"
                      defaultMessage="Electric Vehicles"
                      description="Electric Vehicles Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              ):(null)} */}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  nav
                  caret
                  className={page === "vehicles" ? "active" : ""}
                >
                  <span>
                    <FormattedMessage 
                      id="electricVehicles"
                      defaultMessage="Electric Vehicles"
                      description="Electric Vehicles Header"
                    />
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    role="menuitem"
                    key={`nav-item-new-vehicles`}
                    title={page === "vehicles" ? "Active Page" : null}
                    tag={NavLink}
                    to={"/vehicles"}
                  >
                    <FormattedMessage 
                      id="newVehicles"
                      defaultMessage="New Vehicles"
                      description="New Vehicles Header"
                    />
                    {/* {"New Vehicles"} */}
                  </DropdownItem>
                  <DropdownItem
                    role="menuitem"
                    key={`nav-item-used-vehicles`}
                    title={page === "vehicles" ? "Active Page" : null}
                    tag={NavLink}
                    to={"/used-vehicles"}
                  >
                    <FormattedMessage 
                      id="usedElectricVehicles"
                      defaultMessage="Used Vehicles"
                      description="Used Vehicles Header"
                    />
                  </DropdownItem>
                  <DropdownItem
                    role="menuitem"
                    key={`nav-item-compare-vehicles`}
                    title={page === "vehicles" ? "Active Page" : null}
                    tag={NavLink}
                    to={"/compare-vehicles"}
                  >
                    <FormattedMessage 
                      id="compareVehicles"
                      defaultMessage="Compare Vehicles"
                      description="Compare Vehicles Header"
                    />
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              {/* { process.env.REACT_APP_PAGES_COMPARE_ENABLED  ? (
                <NavItem>
                <NavLink
                  title={page === "compare vehicles" ? "Active Page" : null}
                  to="/compare-vehicles"
                >
                  <span>
                    <FormattedMessage 
                      id="compareVehicles"
                      defaultMessage="Compare Vehicles"
                      description="Compare Vehicles Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              ):(null)}                 */}
              { process.env.REACT_APP_PAGES_INCENTIVES_ENABLED  ? (
                <NavItem>
                <NavLink
                  title={page === "incentives" ? "Active Page" : null}
                  to="/incentives"
                >
                  <span>
                    <FormattedMessage 
                      id="incentives"
                      defaultMessage="Incentives"
                      description="Incentives Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              ):(null)}              
              { process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (
                <NavItem>
                <NavLink
                  title={page === "map" ? "Active Page" : null}
                  to="/charging-stations"
                >
                  <span>
                    <FormattedMessage 
                      id="chargingStations"
                      defaultMessage="Charging Stations"
                      description="Charging Stations Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              ):(null)}              
              { process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED  ? (
              <NavItem>
                <NavLink
                  title={page === process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK ? "Active Page" : null}
                  to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}
                >
                  <span>{process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}</span>
                </NavLink>
              </NavItem> 
              ):(null)}
              { process.env.REACT_APP_PAGES_HOME_CHARGERS_ENABLED  ? (
              <NavItem>
                <NavLink
                  title={page === "home chargers" ? "Active Page" : null}
                  to="/home-chargers"
                >
                  <span>
                    <FormattedMessage 
                      id="homeChargers"
                      defaultMessage="Home Chargers"
                      description="Home Chargers Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              ):(null)} 
              { process.env.REACT_APP_PAGES_DEALERS_ENABLED  ? (
              <NavItem>
                <NavLink
                  title={page === "dealers" ? "Active Page" : null}
                  to="/dealers"
                >
                  <span>
                    <FormattedMessage 
                      id="dealers"
                      defaultMessage="Dealers"
                      description="Dealers Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              ):(null)}              
              { process.env.REACT_APP_PAGES_FAQ_ENABLED ? (              
              <NavItem>
                <NavLink
                  title={page === "faq" ? "Active Page" : null}
                  to="/faq"
                >
                  <span>FAQ</span>
                </NavLink>
              </NavItem>
            ):(null)}              

            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string
};
