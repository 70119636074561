import React from "react";
import PropTypes from "prop-types";
import "./IncentiveCard.scss";
import { FormatAsDollars } from "./../../utils/Helpers/Format";

const IncentiveCard = props => {
  let incentive = props && props.incentive ? props.incentive : {};

  let eligibility = incentive.evaluation && incentive.evaluation.eligibility
  
  if (eligibility === "ineligible") {
    return null
  }

  if (props.usedEv === true && props.incentive.applicable_to_used_vehicles === false) {
    return null 
  }
    
  let name = incentive.grantor + " " + incentive.name || "";
  let description = incentive.description || "";
  let type = incentive.type
  
  let amountDescription =
    incentive.typical_amount && incentive.typical_amount !== "N/A"
      ? incentive.typical_amount.replace("-$", "-")
      : "";

  let amount =
    incentive.evaluation &&
    incentive.evaluation.amount_in_purchase &&
    parseInt(incentive.evaluation.amount_in_purchase, 10) > 0
      ? parseInt(incentive.evaluation.amount_in_purchase, 10)
      : 0;

  let incentiveClassColor = type => {
    switch (type) {
      case 'Tax credit':
        return 'purple'
      case 'HOV lane':
        return 'yellow'
      case 'Rebate':
        return 'aqua'
      case 'Discounted Electric Rate':
        return 'green'
      default:
        return 'purple';
    }
  }

  let renderCard = incentive ? (
    <a
      className="evc-card IncentiveCard"
      href={incentive.details_url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={`IncentiveCardTop ${incentiveClassColor(type)}`}>
        <span>{type}</span>
      </div>
      <div className="IncentiveCardBody">
        <p className="h1 card-title">
          {amount ? FormatAsDollars(amount) : amountDescription}
        </p>
        <p className="h6">{name ? name : ""}</p>
      </div>
      <div className="pl-5 pr-5">
        <hr />
      </div>
      <div className="IncentiveCardBottom">{description}</div>
      {name === "City of Colton EV Charger Rebate" && 
        <a
          href="https://incentiveassistant.electricforall.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn-ae">Apply Now</button>
        </a>
      }
    </a>
  ) : null;

  return renderCard;
};

export default IncentiveCard;

IncentiveCard.propTypes = {
  incentive: PropTypes.object,
  compact: PropTypes.bool
};
