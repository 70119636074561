import React, { useContext } from "react";
import PropTypes from "prop-types";
import calcVehicleRangeForBudget from "../../functions/vehicle/calcVehicleRangeForBudget";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import ShowHideButton from "../ShowHideButton/ShowHideButton";
import { FormatAsCents, FormatCarName } from "../../utils/Helpers/Format";

import { HorizontalBar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";

const FUEL_BUDGET_IN_DOLLARS = 5;

const FuelRangeChart = ({ car, forceUserPrefsPresets, hasExtraPadding }) => {
  const userPrefs = useContext(UserPrefsContext);

  if (!car) return null;
  const equivalentGasVehicle = car.equivalent_gas_vehicle || {};

  const get = forceUserPrefsPresets ? userPrefs.getPreset : userPrefs.get;

  const vehicleRange = Math.round(
    calcVehicleRangeForBudget(
      car,
      FUEL_BUDGET_IN_DOLLARS,
      get("electricMilesPortionForPhev")
    )
  );
  const equivalentGasVehicleRange = Math.round(
    calcVehicleRangeForBudget(equivalentGasVehicle, FUEL_BUDGET_IN_DOLLARS)
  );

  const title = (
    <p className="h3 my-3">
      How far can you drive the {FormatCarName(car)} on a{" "}
      <strong style={{ fontWeight: "800" }}>
        {FormatAsCents(FUEL_BUDGET_IN_DOLLARS)}
      </strong>{" "}
      fuel budget?
    </p>
  );

  const carNames = [
    [FormatCarName(car), "on Plug-In EVerywhere network"],
    [FormatCarName(car), "charged at home"],
    ["Similar Gas Vehicle", FormatCarName(car.equivalent_gas_vehicle)]
  ];

  const chartData = {
    labels: carNames,
    datasets: [
      {
        backgroundColor: ["#f15c2a", "#5ca4d1", "#708090"],
        data: [
          Math.round(vehicleRange * 1.5),
          vehicleRange,
          equivalentGasVehicleRange
        ]
      }
    ]
  };

  const chartOptions = {
    maintainAspectRatio: false,
    tooltips: {
      enabled: false
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            max: Math.round((vehicleRange * 1.7) / 20) * 20,
            stepSize: 20
          }
        }
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            font: {
              weight: 700
            }
          }
        }
      ]
    },
    legend: {
      display: false
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          if (ctx.dataIndex === 0) {
            return "Unlimited";
          }
          return value + " miles";
        },
        align: "end",
        anchor: "end",
        color: "#333333",
        font: {
          weight: 700
        }
      }
    }
  };

  return (
    <div
      className="input-well text-center"
      style={hasExtraPadding ? { padding: "2.5rem" } : { paddfing: "1rem" }}
    >
      {title}
      <div className="d-none d-lg-block d-xl-block">
        <div>
          <HorizontalBar
            data={chartData}
            height={260}
            type="horizontalBar"
            options={chartOptions}
          />
        </div>
      </div>
      {hasExtraPadding && <br />}
      <ShowHideButton buttonText="GRAPH VALUES" displayChildrenOnlyOnTablet>
        <br />
        <div className="row">
          <div className="col-sm-12">
            <table className="table table-sm table-bordered">
              <thead>
                <tr>
                  <th scope="col">Description</th>
                  <th scope="col">
                    Range on {FormatAsCents(FUEL_BUDGET_IN_DOLLARS)} fuel budget
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    {FormatCarName(car)} on Plug-In EVerywhere network
                  </th>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <th scope="row">{FormatCarName(car)} charged at home</th>

                  <td>{vehicleRange} miles</td>
                </tr>
                <tr>
                  <th scope="row">
                    {FormatCarName(car.equivalent_gas_vehicle)} (a similar Gas
                    Vehicle)
                  </th>
                  <td>{equivalentGasVehicleRange} miles</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ShowHideButton>
    </div>
  );
};

export default FuelRangeChart;

FuelRangeChart.propTypes = {
  car: PropTypes.object,
  forceUserPrefsPresets: PropTypes.bool,
  hasExtraPadding: PropTypes.bool
};
