import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import InputWorkingZipcodeForChargingMap from "../InputComponents/InputZipcode/InputZipcodeForChargingMap";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import "./LocationMap.scss";
import {FormattedMessage} from 'react-intl';
import useChargingStations from '../../hooks/useChargingStations';
import filterWithinBounds from '../../utils/Helpers/filterWithinBounds';
import BaseGoogleMapReact from './BaseGoogleMapReact/BaseGoogleMapReact';
import useMappedClusters from '../../hooks/useMappedClusters';
import ChargingStationsMapMarker from './ChargingStationsMapMarker/ChargingStationsMapMarker';
import ChargingStationClusterMarker from './ChargingStationClusterMarker/ChargingStationClusterMarker';
import isSuperChargingStation from '../../utils/predicates/isSuperChargingStation';
import useSelectedStation from '../../hooks/useSelectedStation';
import MapControlPanel from './MapControlPanel/MapControlPanel';
import ChargingStationsList from './ChargingStationsList/ChargingStationsList';

const ChargingMap = ({
  userLocation,
  ignoreSuperchargerStations,
  isVisible = true
}) => {
  const [bounds, setBounds] = useState({
    nw: undefined,
    ne: undefined,
    sw: undefined,
    se: undefined
  });
  const [center, setCenter] = useState();
  const [zoom, setZoom] = useState(10);
  const mapRef = useRef();

  useEffect(() => {
    if (!userLocation) return;
    setCenter({
      lat: parseFloat(userLocation.latitude),
      lng: parseFloat(userLocation.longitude)
    });
  }, [userLocation]);

  const { chargingStations, fetchChargingStations } = useChargingStations();
  const [selectedStation, selectStation, deselectStations] = useSelectedStation(
    chargingStations
  );
  const visibleChargingStations = filterWithinBounds(chargingStations, bounds);
  const filteredChargingStations = ignoreSuperchargerStations ?
    visibleChargingStations.filter(station => !isSuperChargingStation(station)) :
    visibleChargingStations;

  const {
    clusteredPoints,
    individualPoints,
    supercluster
  } = useMappedClusters({
    data: filteredChargingStations,
    bounds,
    zoom
  });

  const onMapChange = ({ center: newCenter, bounds: newBounds, zoom: newZoom }) => {
    setBounds(newBounds);
    setZoom(newZoom);
    const { lat: latitude, lng: longitude } = newCenter;
    fetchChargingStations({ latitude, longitude }, newBounds);
  };

  const saveMapRefs = ({ map }) => {
    mapRef.current = map;
  };

  return (
    <div className="ChargingMap">
      <div className="row hide-offscreen">
        <div className="col-sm-12 text-center">
          <h2>
            <FormattedMessage
              id="chargingStations"
              defaultMessage="Charging Stations"
              description="Charging Stations"
            />
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-3">
          <MapControlPanel
            selectedStation={selectedStation}
            chargingStations={filteredChargingStations}
          >
            <InputWorkingZipcodeForChargingMap />
          </MapControlPanel>
        </div>
        <div className="col-sm-9">
          {!userLocation && (
            <div className="spinner-container">
              <LoadingSpinner />
            </div>
          )}
          {userLocation && (
            <div className="map-container">
              {isVisible && (
                <BaseGoogleMapReact
                  center={center}
                  zoom={zoom}
                  onChange={onMapChange}
                  onGoogleApiLoaded={saveMapRefs}
                >
                  {individualPoints.map((point) => (
                    <ChargingStationsMapMarker
                      key={point.properties.data.id}
                      station={point.properties.data}
                      lat={point.geometry.coordinates[1]}
                      lng={point.geometry.coordinates[0]}
                      onMouseOver={() => {
                        selectStation(point.properties.data.id);
                      }}
                      onClick={deselectStations}
                    />
                  ))}
                  {clusteredPoints.map((cluster) => (
                    <ChargingStationClusterMarker
                      key={cluster.id}
                      cluster={cluster}
                      supercluster={supercluster}
                      map={mapRef.current}
                      lat={cluster.geometry.coordinates[1]}
                      lng={cluster.geometry.coordinates[0]}
                    />
                  ))}
                </BaseGoogleMapReact>
              )}
              <p className="legal-disclaimer">
                <FormattedMessage
                  id="disclaimer.chargingMap"
                  defaultMessage="Data from the Alternative Fuel Data Center may not reflect latest availability. Submit suspected missing charging stations {link}. Please confirm availability of charging stations before commencing your journey."
                  values={{
                    link: (
                      <a
                        href="https://afdc.energy.gov/stations/#/station/new"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{textDecoration: 'underline'}}
                      >
                        here
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="row" style={{marginTop: "10px"}}>
        <div className="col-sm-12">
          <ChargingStationsList chargingStations={filteredChargingStations} />
        </div>
      </div>
    </div>
  );
};

export default ChargingMap;

ChargingMap.propTypes = {
  userLocation: PropTypes.object,
  ignoreSuperchargerStations: PropTypes.bool
};
